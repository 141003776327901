.App {
  text-align: center;
}


.App-header {
  background-color: #282c34;
  position: sticky;
  top: 0;
  z-index: 1000; /* big number to ensure always on top */
}

.App-body {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;


}

.home-container {
  display: flex;
  felx-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('background1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5) /* matte effect */
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.content h1 {
  font-size: 2.0em;
  margin: 10px 0;
}

.content p {
  font-size: 1.2em;
  margin: 10px 0;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%; /* circular frame */
  border: 3px solid white;
  margin-bottom: 20px;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  color: white;
  margin: 0 10px;
  font-size: 1.5em;
  transition: color 0.3s;
}

.social-links a:hover {
  color: #0077b5;
}

@media (max-width: 786px) {
  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .content h1 {
    font-size: 2em;
  }

  .content p {
    font-size: 1.2em;
  }

  .social-links a {
    font-size: 1.2em;
  }
}

.home-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.project-explorer-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
}

.project-explorer-tabs {
  width: 90%;
  font-size: 0.6em;
}

.project-explorer-breadcrumb {
  color: white;
  font-size: 0.6em;
  padding-top: 10px;
  padding-left: 10%;
}

.breadcrumb .active {
  color: white !important;
}

.breadcrumb a {
  color: white;
}

.breadcrumb a:hover {
  color: #0077b5
}

/* Targeting the default slash separators */
.breadcrumb-item+.breadcrumb-item::before {
  color: white !important;
}


.smallsh-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
}

.aicrypto-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
}

.App-link {
  color: #61dafb;
}


.iframe-wrapper {
  position: relative;
  width: 75%;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border: 1px solid #ccc;
  border-radius: 8px;
}

/* Responsive iframe */
.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Custom scrollbar styles */
.iframe-container iframe::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.iframe-container iframe::-webkit-scrollbar-track {
  background: #282c34;
  border-radius: 8px;
}

.iframe-container iframe::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.iframe-container iframe::-webkit-scrollbar-thumb:hover {
  background: #555;
}
